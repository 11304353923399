body {
  font-family: 'Raleway', sans-serif;
}

#root {
  position: relative;
}

textarea {
  resize: none;
}

.navbar {
  transition: background-color 0.5s ease-in-out;
  background-color: transparent;
}

.navbar-toggler {
  cursor: pointer;
  border: none;
}

.navbar-toggler:focus {
  outline: none;
}

.navbar.bg-animate {
  background-color: rgba(27, 34, 76, 0.5);
}

.navbar.bg-animate .nav-link {
  color: white !important;
}

.nav-link {
  transition: color 0.5s ease-in-out;
  color: #1b224c !important;
  font-weight: 800;
  text-align: center;
}

.sbx-logo {
  height: 43px;
}

.presentation-mobile-business-text {
  position: absolute;
  top: 40%;
  left: 5%;
  color: white;
  width: 510px;
}

.presentation-mobile-business-text h1 {
  font-weight: 800;
}

.combined-images-presentation {
  position: relative;
}

.combined-images-presentation img {
  position: absolute;
  right: 0;
  top: 130px;
}

.combined-images-presentation img:first-child {
  top: 250px;
}

.we-do-header, .expertize-header {
  font-weight: 800;
}

.we-do-header {
  color: #fe1a66;
}

.expertize-header {
  color: #0abcff;
}

.subheader {
  color: #424753;
}

.we-do-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.we-do-item img {
  width: 80px;
  height: 80px;
  margin-bottom: 1.5rem;
}

.expertize-item img {
  max-width: 230px;
  max-height: 140px;
}

.we-do-item h5 {
  color: #1b224c;
  font-weight: 800;
  font-size: 16px;
  min-height: 60px;
  text-align: center;
}

.we-do-item p {
  color: #424753;
  font-size: 14px;
  text-align: justify;
}

.bg-figure3:before {
  content: '';
  background: url('./images/figure3.png');
  background-size: 100% 100%;
  height: 336px;
  width: 332px;
  position: absolute;
  left: 0;
  top: 1480px;
  z-index: -1;
}

.bg-figure4:after {
  content: '';
  background: url('./images/figure4.png');
  background-size: cover;
  height: 380px;
  width: 418px;
  position: absolute;
  right: 0;
  top: 2100px;
  z-index: -1;
}

.bg-figure5:before {
  content: '';
  background: url('./images/figure5.png');
  background-size: cover;
  width: 100vw;
  height: 980px;
  position: absolute;
  left: 0;
  z-index: -1;
  bottom: 0;
}

.bg-figure6:after {
  content: '';
  background: url('./images/figure6.png');
  height: 220px;
  position: absolute;
  background-size: cover;
  width: 100%;
  z-index: -1;
  bottom: 0;
}

#contactUs {
  padding-top: 300px;
}

.hiring-apply-btn {
  cursor: pointer;
  width: 63px;
  height: 28px;
  border-radius: 8px;
  border: solid 1px #e8e9ed;
  color: white;
}

.hiring-apply-btn:hover {
  color: #1b224c;
  background-color: white;
  text-decoration: none;
}

.hiring-img {
  width: 143px;
  height: 111px;
}

.card-hiring {
  background-color: #21295a;
  border-radius: 13px;
}

.btn-contact-send {
  width: 128px;
  height: 44px;
  border-radius: 32px;
  box-shadow: -4px 8px 0 0 #21295a;
  color: white;
  font-size: 14px;
  font-weight: 800;
  border: none;
  cursor: pointer;
  background: #fe1458 linear-gradient(to left, #f955a3, #fe1458);
  transition: background-color 0.5s ease-in-out;
}

.btn-contact-send:hover {
  background: #fe1458 none;
}

.btn-contact-send:focus {
  outline: none;
}

.btn-contact-send[disabled] {
  background: #f955a3 none;
}

.grecaptcha-badge {
  bottom: 65px !important;
}

.sbx-footer p {
  margin: 0;
}

.sbx-footer a {
  color: white;
}

@media (max-width: 1366px) {
  .bg-figure3:before {
    height: 256px;
    width: 252px;
  }

  .bg-figure4:after {
    height: 250px;
    width: 288px;
  }
}

@media (max-width: 1024px) {
  .presentation-mobile-business-text {
    width: 440px;
  }

  #contactUs {
    padding-top: 180px;
  }

  .bg-figure3:before {
    height: 216px;
    width: 212px;
    top: 1360px;
  }

  .bg-figure4:after {
    top: 1970px;
  }

  .bg-figure5:before {
    height: 900px;
  }
}

@media (max-width: 768px) {
  .presentation-mobile-business-text {
    top: 25%;
    width: 360px;
  }

  .bg-figure3:before {
    display: none;
  }

  .bg-figure4:after {
    display: none;
  }

  .combined-images-presentation {
    top: -455px;
    min-height: 230px;
  }

  .we-do-item h5 {
    min-height: 40px
  }

  .expertize-item img {
    max-width: 180px;
    max-height: 90px;
  }

  .bg-figure5:before {
    height: 1380px;
  }
}

@media (max-width: 425px) {
  .sbx-logo {
    height: 27px;
  }

  .navbar {
    background-color: rgba(27, 34, 76, 0.5);
  }

  .navbar .nav-link {
    color: white !important;
  }

  .combined-images-presentation {
    top: -250px;
    min-height: 430px;
  }

  .expertize-item img {
    max-width: 150px;
    max-height: 60px;
  }

  .bg-figure5:before {
    height: 1530px;
  }

  #contactUs {
    padding-top: 140px;
  }
}

.acps {
  width: 200px;
  height: 180px;
}

.oprtnr {
  width: 100%;
  height: auto;
}
